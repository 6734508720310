import axios from "axios";
import config from "../config";

let templates = {
  saveTemplate: (weekId, name) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/saveTemplate",
        data: { weekId: weekId, name: name },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadTemplate: (templateId, weekId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/loadTemplate",
        data: { weekId: weekId, templateId: templateId },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeTemplate: (templateId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/removeTemplate",
        data: { templateId: templateId },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTemplates: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchTemplates",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default templates;
