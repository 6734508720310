import i18n from "../plugins/i18n";

let staticData = {
  statisticsPeriods: [
    { name: i18n.t("gbl-periods-lastyear"), id: 0 },
    { name: i18n.t("gbl-periods-since1year"), id: 1 },
    { name: i18n.t("gbl-periods-thisyear"), id: 2 },
    { name: i18n.t("gbl-periods-last6months"), id: 3 },
    { name: i18n.t("gbl-periods-last90days"), id: 4 },
    { name: i18n.t("gbl-periods-last60days"), id: 5 },
    { name: i18n.t("gbl-periods-last30days"), id: 6 },
    { name: i18n.t("gbl-periods-lastmonth"), id: 7 },
    { name: i18n.t("gbl-periods-thismonth"), id: 8 },
    { name: i18n.t("gbl-periods-today"), id: 9 },
    { name: i18n.t("gbl-periods-yesterday"), id: 10 },
    { name: i18n.t("gbl-periods-this-week"), id: 15 },
    { name: i18n.t("gbl-periods-24h"), id: 11 },
    { name: i18n.t("gbl-periods-48h"), id: 12 },
    { name: i18n.t("gbl-periods-date"), id: 13 },
    { name: i18n.t("gbl-periods-all"), id: 14 },
  ],

  days: [
    { name: i18n.t("day-monday"), id: 0 },
    { name: i18n.t("day-tuesday"), id: 1 },
    { name: i18n.t("day-wednesday"), id: 2 },
    { name: i18n.t("day-thursday"), id: 3 },
    { name: i18n.t("day-friday"), id: 4 },
    { name: i18n.t("day-saturday"), id: 5 },
    { name: i18n.t("day-sunday"), id: 6 },
  ],

  dataTableFooterOptions: {
    showFirstLastPage: true,
    "items-per-page-options": [5, 10, 20, 50, 100, -1],
  },

  defaultPerPage: 50,
};

export default staticData;
