import axios from "axios";
import config from "../config";

let shifts = {
  fetchReasons: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/fetchReasons",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchShifts: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addShift: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/add",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/remove",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  enableShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/enable",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  disableShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/disable",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendShiftToPlanner: (id, weekId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/sendShiftToPlanner",
        data: { id: id, weekId: weekId },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateShift: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/shifts/update",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAccountShifts: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/accountShifts/fetch",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addAccountShift: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/accountShifts/add",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeAccountShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/accountShifts/remove",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAccountShift: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/accountShifts/update",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default shifts;
