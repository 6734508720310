import axios from "axios";
import config from "../config";
import localStorageService from "../services/localStorageService";

let planner = {
  fetchShifts(ctx) {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchShifts",
        data: {
          ctx: ctx,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addException: (day, user, exception_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/addException",
        data: {
          day: day,
          user: user,
          exception_id: exception_id,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeException: (day, user) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/removeException",
        data: {
          day: day,
          user: user,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editNotes: (id, notes) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/editNotes",
        data: {
          id: id,
          notes: notes,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  exportShiftsToInfinity: (ctx) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/planner/exportShiftsToInfinity?ctx=" +
          encodeURIComponent(ctx) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  exportShiftsToExcel: (ctx) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/planner/exportShiftsToExcel?ctx=" +
          encodeURIComponent(ctx) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  exportShiftsToPdf: (ctx) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/planner/exportShiftsToPdf?ctx=" +
          encodeURIComponent(ctx) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  exportShiftsToCsv: (ctx) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/planner/exportShiftsToCsv?ctx=" +
          encodeURIComponent(ctx) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  changeShiftEffectiveTimes: (id, shift_start_time, shift_end_time) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/changeShiftEffectiveTimes",
        data: {
          id: id,
          shift_start_time: shift_start_time,
          shift_end_time: shift_end_time,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  voidShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/voidShift",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  unvoidShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/unvoidShift",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  convalidateShift: (id, shift_start_time, shift_end_time) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/convalidateShift",
        data: {
          id: id,
          shift_start_time: shift_start_time,
          shift_end_time: shift_end_time,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  invalidateShift: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/invalidateShift",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPlannerShifts: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchPlannerShifts",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadPlannerExcel: (weekId) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      link.setAttribute(
        "href",
        config.apiEndPoint +
          "/planner/excel?week_id=" +
          encodeURIComponent(weekId) +
          "&token=" +
          encodeURIComponent(token)
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  fetchWorkers: (weekId, filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWorkers",
        data: { weekId: weekId, filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.workers);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  putShiftBack: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/putShiftBack",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  splitShiftOnPlanner: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/splitShiftOnPlanner",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  duplicateShiftOnPlanner: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/duplicateShiftOnPlanner",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  mergeShiftsOnPlanner: (bottomShiftID, topShiftId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/mergeShiftsOnPlanner",
        data: { topShiftId: topShiftId, bottomShiftID: bottomShiftID },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteShiftOnPlanner: (week_shift_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/deleteShiftOnPlanner",
        data: { week_shift_id: week_shift_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  putShiftOnPlanner: (week_shift_id, account_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/putShiftOnPlanner",
        data: { week_shift_id: week_shift_id, account_id: account_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  putBackInUnassigned: (week_shift_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/putBackInUnassigned",
        data: { week_shift_id: week_shift_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createShiftOnPlanner: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/createShiftOnPlanner",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateShiftOnPlanner: (shift) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/updateShiftOnPlanner",
        data: { shift: shift },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWeekState: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWeekState",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWeeks: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWeeks",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.weeks,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWeekDays: (weekId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWeekDays",
        data: { weekId: weekId },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.days);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWeekStatus: (day) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWeekStatus",
        data: { day: day },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.count);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWeekUnassignedShifts: (
    weekId,
    customerId,
    addressId,
    operator,
    day,
    times
  ) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWeekUnassignedShifts",
        data: {
          weekId: weekId,
          customerId: customerId,
          addressId: addressId,
          operator: operator,
          day: day,
          times: times,
        },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchWeekAssignedShifts: (weekId, workerId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/fetchWeekAssignedShifts",
        data: { weekId: weekId, workerId: workerId },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.shifts);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteWeek: (weekId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/deleteWeek",
        data: { weekId: weekId },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addWeek: (week, templateId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/addWeek",
        data: { week: week, templateId: templateId },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editWeek: (week) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/planner/editWeek",
        data: { week: week },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default planner;
